export class Profile {
    firstName: string
    lastName: string
    mobileNumber: string
    countryCode: string
    dob: string
    companyName: string
    email: string
    city: string
    area: string
    landmark: string
    country: string
}

export interface Password {
    oldPassword: string
    newPassword: string
    confirmPassword: string
}
