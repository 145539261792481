import { HttpParams } from '@angular/common/http'

export class FilterParams {

    limit: string
    offset: string
    discountMin: string
    discountMax: string
    priceStart: string
    priceEnd: string
    brand: string
    _Origin: string
    _Botanical$Species: string
    _Botanical$Variety: string
    _Process:string
    _Certifications:string

    constructor(limit: string = null, offset: string = null,_Origin: string = null,_Botanical$Species: string = null,_BotanicalVariety:string=null,_Process:string=null,_Certifications:string=null, discountMin: string = null, discountMax: string = null, priceStart: string = null, priceEnd: string = null, brand: string = null) {
        this.limit = limit
        this.offset = offset
        this.discountMin = discountMin
        this.discountMax = discountMax
        this.priceStart = priceStart
        this.priceEnd = priceEnd
        this.brand = brand
        this._Botanical$Species = _Botanical$Species
        this._Origin = _Origin
        this._Botanical$Variety = _BotanicalVariety
         this._Process = _Process
        this._Certifications = _Certifications
        return this
    }

    public getAvailableFilters() {
        return Object.entries(this).filter(([key, value]) => {
            return value !== null
        })
    }

    public toHttpParams() {
        let params = new HttpParams()
        this.getAvailableFilters().forEach(([key, value]) => {
            params = params.append(key.replace("$"," "), value)
        })
        return params
    }

    public toHttpQueryParams() {
        const params = new URLSearchParams()
        this.getAvailableFilters().forEach(([key, value]) => {
            params.set(key.replace("$"," "), value)
        })
        return params
    }

}
